import { mapHelper } from '@/utils/utils.js';

// 开门状态
const openDoorStatus = [
  { value: 1, text: '开门成功' },
  { value: 0, text: '开门失败' }
];

const { map: openDoorStatusMap, setOps: setOpenDoorStatusOps } = mapHelper.setMap(openDoorStatus);

export { openDoorStatus, openDoorStatusMap, setOpenDoorStatusOps };
