<template>
  <div class="open-door-record-list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      :immediate-check="false"
      @load="getList"
    >
      <div v-for="(item, index) in list" :key="'kmlsjl' + index" class="open-door-record-item">
        <div class="kmsj-text">{{ item.openTime }}</div>
        <div class="kmjg-text" :class="[{ success: item.isSucess === 1 }, { fail: item.isSucess === 0 }]">
          {{ openDoorStatusMap[item.isSucess] }}
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getOpenDoorRecordListUrl } from './api.js';
import { openDoorStatusMap } from './map.js';
import { mapState } from 'vuex';

export default {
  name: 'openDoorRecordList',
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  data() {
    return {
      openDoorStatusMap,
      loading: false,
      finished: false,
      error: false,
      list: [],
      total: 0,
      queryForm: {
        curPage: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.finished = false;
      const params = {
        ...this.queryForm,
        userId: this.userId
      };
      this.$axios
        .get(getOpenDoorRecordListUrl, { params })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
            this.list.push(...res.data.records);
            this.queryForm.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.list.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.open-door-record-list {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 30px;
  .open-door-record-item {
    box-sizing: border-box;
    margin-bottom: 30px;
    height: 110px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    font-size: 30px;
    line-height: 42px;
    color: #333333;
    .kmjg-text {
      font-weight: 600;
      &.success {
        color: #40ca44;
      }
      &.fail {
        color: #ca4040;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
