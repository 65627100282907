// 查询 - 个人信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;

// 操作 - 开门
const handOpenDoorUrl = `/gateway/hc-serve/miniapi/remoteOpenDoor/addRecord`;

// 查询 - 开门 - 历史记录 - 列表
const getOpenDoorRecordListUrl = `/gateway/hc-serve/miniapi/remoteOpenDoor/getRecordList`;

export { userInfoUrl, handOpenDoorUrl, getOpenDoorRecordListUrl };
